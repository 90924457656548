import Header from "app/header/components/Header"
import { PropsWithChildren, Suspense, use, useEffect, useState } from "react"
import Layout from "./Layout"
import HelpButton from "../components/HelpButton"
import { SUPPORT_LINKS } from "../consts"
import styled from "@emotion/styled"
import PlaylistModal from "app/playlists/components/PlaylistModal"
import MaintenanceBar from "../components/MaintenanceBar"
import { useQuery } from "@blitzjs/rpc"
import getMaintenance from "../queries/getMaintenance"
import ArchiveModal from "app/schooljaren/components/ArchiveModal"
import Footer from "app/footer/components/Footer"
import { useRouter } from "next/router"
import { Routes } from "@blitzjs/next"
import Stack from "ui/Stack"
import getCurrentUser from "app/users/queries/getCurrentUser"
import useRouterQueryParam from "../hooks/useRouterQueryParam"
import { MODAL_QUERY_KEY } from "app/playlists/consts"
import { AnalyticsObject } from "app/analytics/utils/generateAnalyticsObject"
import { useCurrentGroup } from "../hooks/useCurrentGroup"
import getAnalyticsUserGroups from "app/analytics/utils/getAnalyticsUserGroups"

const Root = styled.div`
  /* padding-bottom: 100px; */
`

export const StackWithBackground = styled(Stack)<{ backgroundColor?: string }>`
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : undefined};
`

export default function DashboardLayout(
  props: PropsWithChildren<{
    title?: string | null
    type?: keyof typeof SUPPORT_LINKS
    backgroundColor?: string
    hideFooter?: boolean
    disablePadding?: boolean
    analyticsObject?: Partial<AnalyticsObject>
  }>
) {
  const [currentUser, currentUserRes] = useQuery(getCurrentUser, {})

  const [maintenance] = useQuery(getMaintenance, undefined)
  const router = useRouter()
  const [isPlaylistModalLoaded, setIsPlaylistModalLoaded] = useState(false)

  const [queryParam, setOpen] = useRouterQueryParam(MODAL_QUERY_KEY, "string")

  const group = useCurrentGroup()

  useEffect(() => {
    if (
      currentUser &&
      !currentUserRes.isLoading &&
      !currentUserRes.isFetching &&
      currentUser.organizationUsers &&
      currentUser.organizationUsers.length > 0
    ) {
      if (currentUser.organizationUsers[0].onboardingType === "ONBOARDING") {
        router.push(
          Routes.OnboardingGroup({
            next: router.query.next,
          })
        )
      } else if (
        currentUser.organizationUsers[0].onboardingType === "SCHOOLYEAR"
      ) {
        router.push(
          Routes.ReboardingWelkomPage({
            next:
              Routes.ReboardingWelkomPage().pathname === router.query.next
                ? undefined
                : router.query.next,
          })
        )
      }
    }
  }, [router, currentUser, currentUserRes])

  return (
    <Layout
      {...props}
      analyticsObject={{
        ...props.analyticsObject,
        userType: currentUser?.role,
        ...getAnalyticsUserGroups(group?.grades),
      }}
    >
      <MaintenanceBar
        message={maintenance === "false" ? undefined : maintenance || undefined}
      />
      <Root>
        {props.type ? <HelpButton type={props.type} /> : null}
        <Header
          user={currentUser}
          setIsPlaylistModalLoaded={setIsPlaylistModalLoaded}
          group={group}
        />
        <Suspense>
          {(isPlaylistModalLoaded || typeof queryParam !== "undefined") && (
            <PlaylistModal />
          )}
        </Suspense>
        <ArchiveModal />
        <StackWithBackground
          gap={props.disablePadding ? "0px" : "100px"}
          backgroundColor={props.backgroundColor}
        >
          <div>{props.children}</div>
          {!props.hideFooter && <Footer />}
        </StackWithBackground>
      </Root>
    </Layout>
  )
}
DashboardLayout.suppressFirstRenderFlicker = true
