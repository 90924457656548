
import { ImageContext } from "utils/files/useFilePathContext"
import { useQuery } from "@blitzjs/rpc"
import getFileUrlPrefix from "../queries/getFileUrlPrefix"
import { useEffect, useMemo } from "react"
import * as fncs from "utils/files/getFilePath"

const FilePathProvider = ({ children }: { children: React.ReactNode }) => {
  const [prefixes] = useQuery(getFileUrlPrefix, undefined)
  const getFilePath = useMemo(() => fncs.getFilePath(prefixes), [prefixes])
  const getFileVariantUrl = useMemo(() => fncs.getFileVariantUrl(prefixes), [prefixes])
  
  return (
    <ImageContext.Provider value={{ getFilePath, getFileVariantUrl }}>
      {children}
    </ImageContext.Provider>
  )
}

export default FilePathProvider
