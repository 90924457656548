import { Routes } from "@blitzjs/next"
import Stack from "ui/Stack"
import { PX14 } from "theme/sizes"
import styled from "@emotion/styled"
import ImageBase from "app/files/components/Image"
import NextImageBase from "next/image"
import TextBase from "ui/Text"
import Group from "ui/Group"
import router from "next/router"
import { MutableRefObject } from "react"
import { DisplayStateType } from "app/radio/types"
import { useAtomValue } from "jotai"
import { playingStateAtom, playingThemeAtom } from "app/radio/state"
import { DARK_BLUE_COLOR } from "theme/colors"
import { css } from "@emotion/react"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import { MODAL_QUERY_KEY } from "app/playlists/consts"

const Root = styled(Group)`
  height: inherit;
  width: 100%;
  position: relative;
`

const GradientOverlay = styled.div<{ theme?: "light" | "dark" }>`
  position: absolute;
  height: inherit;
  width: 100%;
  pointer-events: none;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 85%,
    ${(p) => (p.theme === "light" ? "white" : DARK_BLUE_COLOR)} 100%
  );

  content: "";
  display: block;
  top: 0;
  left: 0;
`

const Image = styled(ImageBase)`
  border-radius: 4px;
  flex-shrink: 0;
  cursor: pointer;
`

const NextImage = styled(NextImageBase)`
  border-radius: 4px;
`

const TextContainer = styled(Stack)<{
  displayState: DisplayStateType
  libraryOpen?: boolean
}>`
  overflow: hidden;
  ${(p) =>
    !(p.displayState === "large" || p.libraryOpen) &&
    css`
      max-width: 164px;
    `}
`

const Text = styled(TextBase)`
  /*color: white;*/
  white-space: nowrap;
`

const TextLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const NowPlayingInfo = ({
  displayState,
  containerRef,
  theme,
  libraryOpen,
}: {
  displayState: DisplayStateType
  containerRef: MutableRefObject<any>
  theme?: "light" | "dark"
  libraryOpen?: boolean
}) => {
  const item = useAtomValue(playingStateAtom)
  const playingTheme = useAtomValue(playingThemeAtom)
  const [open, setOpen] = useRouterQueryParam(MODAL_QUERY_KEY, "number")
  return (
    <Root gap="16px" align="center">
      {item?.lesson.listImage ? (
        <Image
          file={item.lesson.listImage}
          width={58}
          height={58}
          alt=""
          onClick={async () => {
            await router.push(
              Routes.LessonPage({
                lesson: item.lesson.slug,
              })
            )
          }}
        />
      ) : (
        <NextImage
          src="/images/logo-square-placeholder.svg"
          width={58}
          height={58}
          alt=""
        />
      )}
      {item ? (
        <>
          {/*{(containerRef.current && containerRef.current.offsetWidth > 769) ||
          displayState === "small" ? (*/}
          <TextContainer
            align="flex-start"
            displayState={displayState}
            libraryOpen={libraryOpen}
          >
            <TextLink
              size={PX14}
              bold
              onClick={async () => {
                if (playingTheme?.route)
                  return await router.push(playingTheme.route)
                if (playingTheme?.libraryPlaylistId)
                  return setOpen(playingTheme.libraryPlaylistId)
              }}
            >
              {playingTheme?.listName}
            </TextLink>
            <TextLink
              size={PX14}
              onClick={async () => {
                if (!item) return
                await router.push(
                  Routes.LessonPage({
                    lesson: item.lesson.slug,
                  })
                )
              }}
            >
              {item?.lesson.displayTitle}
            </TextLink>
          </TextContainer>
          {/*) : null}*/}
        </>
      ) : null}
      <GradientOverlay theme={theme} />
    </Root>
  )
}

export default NowPlayingInfo
