import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { FaPause } from "@react-icons/all-files/fa/FaPause"
import { FaPlay } from "@react-icons/all-files/fa/FaPlay"
import { TbArrowBarToLeft } from "@react-icons/all-files/tb/TbArrowBarToLeft"
import { TbArrowsDiagonal } from "@react-icons/all-files/tb/TbArrowsDiagonal"
import { TbArrowsDiagonalMinimize2 } from "@react-icons/all-files/tb/TbArrowsDiagonalMinimize2"
import { TbDots } from "@react-icons/all-files/tb/TbDots"
import { TbList } from "@react-icons/all-files/tb/TbList"
import { TbX } from "@react-icons/all-files/tb/TbX"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import IconContainer from "app/player/components/IconContainer"
import { MODAL_QUERY_KEY } from "app/playlists/consts"
import NowPlayingInfo from "app/radio/components/NowPlayingInfo"
import { usePlay, useTogglePlaySpace } from "app/radio/components/PlayerContext"
import PlaylistControls from "app/radio/components/PlaylistControls"
import RadioBoxContextMenu from "app/radio/components/RadioBoxContextMenu"
import RadioOptions from "app/radio/components/RadioOptions"
import { Routes } from "@blitzjs/next"
import { TbMusic } from "@react-icons/all-files/tb/TbMusic"
import Tooltip from "app/radio/components/Tooltip"
import { TRANSITION } from "app/radio/consts"
import {
  displayStateAtom,
  isPlayingAtom,
  playingStateAtom,
  playingThemeAtom,
} from "app/radio/state"
import { useAtom, useAtomValue } from "jotai/index"
import { useRouter } from "next/router"
import { MutableRefObject, useMemo, useState } from "react"
import { DARK_BLUE_COLOR, DIMMED_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import ButtonBase from "ui/Button"
import ContextMenuBase from "ui/ContextMenu"
import Group from "ui/Group"
import HrBase from "ui/Hr"
import { Content } from "./RadioBox"
import { Value } from "@stringtale/react"

const Button = styled(ButtonBase)<{ isActive?: boolean }>`
  ${TRANSITION};
  width: 48px;
  height: 48px;
  border-radius: 6px;
  background-color: ${DARK_BLUE_COLOR};
  color: ${(p) => (p.isActive ? ORANGE_COLOR : "white")};
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.isActive &&
    css`
      &:hover {
        color: #c8320d;
      }
    `}
`

const RoundButton = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${DARK_BLUE_COLOR};
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseButton = styled.div`
  ${TRANSITION};
  height: 58px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &:hover {
    border: 1px solid ${DIMMED_BLUE_COLOR};
  }
`

export const PlayButton = styled(RoundButton)`
  ${TRANSITION};
  width: 48px;
  height: 48px;
  background-color: #ed7044;

  svg {
    width: 20px;
    height: 20px;
    flex: 1 0 20px;
  }

  &:hover {
    background-color: #c8320d;
  }
`

const OptionsButton = styled(RoundButton)<{ dropdownActive?: boolean }>`
  ${TRANSITION};
  border: 1px solid #353371;
  &:hover {
    border: 1px solid ${DIMMED_BLUE_COLOR};
  }
  ${(p) =>
    p.dropdownActive
      ? css`
          color: ${DARK_BLUE_COLOR};
          background-color: #fff;
        `
      : css`
          color: #fff;
          background-color: ${DARK_BLUE_COLOR};
        `}
`

const ContextMenuBaseItem = styled(ContextMenuBase.Item)`
  padding: 8px 8px;
`

const Hr = styled(HrBase)`
  height: 1px;
`

const RadioBoxContent = ({
  containerRef,
  libraryOpen,
}: {
  containerRef: MutableRefObject<any>
  libraryOpen: boolean
}) => {
  const router = useRouter()
  const playingTheme = useAtomValue(playingThemeAtom)
  const isPlaying = useAtomValue(isPlayingAtom)

  const [item, setItem] = useAtom(playingStateAtom)
  const [displayState, setDisplayState] = useAtom(displayStateAtom)

  const [dropdownActive, setDropdownActive] = useState<boolean>(false)

  const [_, setOpen] = useRouterQueryParam(MODAL_QUERY_KEY, "number")

  useTogglePlaySpace()
  const { togglePlay, pause } = usePlay()

  const ContextMenu = useMemo(
    () => (
      <RadioBoxContextMenu
        open={dropdownActive}
        onOpenChange={setDropdownActive}
        trigger={
          <OptionsButton dropdownActive={dropdownActive}>
            <TbDots />
          </OptionsButton>
        }
        items={
          <>
            <DropdownMenu.Item
              asChild
              onClick={() => setDisplayState("closed")}
            >
              <ContextMenuBaseItem>
                <Group gap="10px">
                  <TbArrowBarToLeft size={20} />
                  <Value name="apps.web.src.radio.components.radioboxcontent.radio_verbergen">
                    Radio verbergen
                  </Value>
                </Group>
              </ContextMenuBaseItem>
            </DropdownMenu.Item>
            {displayState === "small" && (
              <DropdownMenu.Item
                asChild
                onClick={() => setDisplayState("large")}
              >
                <ContextMenuBaseItem>
                  <Group gap="10px">
                    <TbArrowsDiagonal size={20} />
                    <Value name="apps.web.src.radio.components.radioboxcontent.radio_vergroten">
                      Radio vergroten
                    </Value>
                  </Group>
                </ContextMenuBaseItem>
              </DropdownMenu.Item>
            )}
            {displayState === "large" && (
              <DropdownMenu.Item
                asChild
                onClick={() => setDisplayState("small")}
              >
                <ContextMenuBaseItem>
                  <Group gap="10px">
                    <TbArrowsDiagonalMinimize2 size={20} />
                    <Value name="apps.web.src.radio.components.radioboxcontent.radio_verkleinen">
                      Radio verkleinen
                    </Value>
                  </Group>
                </ContextMenuBaseItem>
              </DropdownMenu.Item>
            )}
            <Hr />
            <DropdownMenu.Item
              asChild
              onClick={async () => {
                if (!playingTheme) return
                if (playingTheme?.route)
                  return await router.push(playingTheme.route)
                if (playingTheme?.libraryPlaylistId)
                  return setOpen(playingTheme.libraryPlaylistId)
              }}
            >
              <ContextMenuBaseItem>
                <Group gap="10px">
                  <TbList size={20} />
                  <Value name="apps.web.src.radio.components.radioboxcontent.ga_naar_lijst">
                    Ga naar lijst
                  </Value>
                </Group>
              </ContextMenuBaseItem>
            </DropdownMenu.Item>
            {item && (
              <DropdownMenu.Item
                asChild
                onClick={async () => {
                  await router.push(
                    Routes.LessonPage({
                      lesson: item.lesson.slug,
                    })
                  )
                }}
              >
                <ContextMenuBaseItem>
                  <Group gap="10px">
                    <TbMusic size={20} />
                    <Value name="apps.web.src.radio.components.radioboxcontent.ga_naar_het_lied">
                      Ga naar het lied
                    </Value>
                  </Group>
                </ContextMenuBaseItem>
              </DropdownMenu.Item>
            )}
            <Hr />
            <DropdownMenu.Item
              asChild
              onClick={async () => {
                await setDisplayState("hidden")
                await pause()
              }}
            >
              <ContextMenuBaseItem>
                <Group gap="10px">
                  <TbX size={20} />
                  <Value name="apps.web.src.radio.components.radioboxcontent.sluit_radio">
                    Sluit radio
                  </Value>
                </Group>
              </ContextMenuBaseItem>
            </DropdownMenu.Item>
          </>
        }
      />
    ),
    [
      displayState,
      dropdownActive,
      item,
      pause,
      playingTheme,
      router,
      setDisplayState,
      setOpen,
    ]
  )

  return (
    <Content>
      {!libraryOpen && (
        <CloseButton onClick={() => setDisplayState("closed")}>
          <IconContainer
            url="/icons/player/ico_chev_left_w.svg"
            width="25px"
            height="25px"
          />
        </CloseButton>
      )}
      <NowPlayingInfo
        containerRef={containerRef}
        displayState={displayState}
        libraryOpen={libraryOpen}
      />
      {displayState === "large" || libraryOpen ? (
        <PlaylistControls containerRef={containerRef} />
      ) : (
        <Group gap="10px" align="center">
          <Tooltip
            text={isPlaying ? "Pauzeer" : "Afspelen"}
            trigger={
              <PlayButton onClick={togglePlay} disabled={!item}>
                {isPlaying ? <FaPause /> : <FaPlay style={{ marginLeft: 4 }} />}
              </PlayButton>
            }
          />
          {ContextMenu}
        </Group>
      )}
      {displayState === "large" || libraryOpen ? (
        <RadioOptions
          containerRef={containerRef}
          contextMenu={libraryOpen ? null : ContextMenu}
        />
      ) : null}
    </Content>
  )
}

export default RadioBoxContent
