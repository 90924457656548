
// Remove or add an item to an array
const toggleArray = <T>(item: T, val?: boolean) => (array: T[]) => {
  if (array.includes(item)) {
    if (val === true) return array
    return array.filter((i) => i !== item)
  }
  if (val === false) return array
  return [...array, item]
}
export default toggleArray