import { SetStateAction } from "jotai"
import { useRouter } from "next/router"
import { Dispatch, useCallback, useMemo } from "react"

export default function useRouterQueryParamList(name: string, type: "string"): readonly [string[], Dispatch<SetStateAction<string[]>>];
export default function useRouterQueryParamList(name: string, type: "number"): readonly [number[], Dispatch<SetStateAction<number[]>>];

export default function useRouterQueryParamList<T extends "string" | "number">(name: string, type: T) {
  const router = useRouter()
  const getter = useCallback(() => {
    let value = router.query[name]
    if (!value) {
      value = []
    } else if (!Array.isArray(value)) {
      value = [value]
    }
    if (type === "number") {
      return value.map((v) => parseInt(v))
    }
    return value
  }, [name, router.query, type])
  const value = useMemo(() => getter(), [getter])
  const setter = (newValueOrFunction: SetStateAction<any>) => {
    if (typeof newValueOrFunction === "function") {
      newValueOrFunction = newValueOrFunction(value)
    }
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        [name]: newValueOrFunction,
      },
    })
  }
  return [value, setter] as const
}
