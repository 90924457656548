import styled from "@emotion/styled"
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH"
import { Value } from "@stringtale/react"
import {
  EmptyContainer,
  EmptyImage,
} from "app/bulletinboard/components/BulletinAddItemPlaceholder"
import BulletinAddListItem from "app/bulletinboard/components/BulletinAddListItem"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import Avatar from "app/files/components/Avatar"
import Image from "app/files/components/Image"
import { GetSchoolListsResult } from "app/schoolbeheer/queries/getSchoolLists"
import { UserPlaylist } from "db"
import {
  DARK_BLUE_COLOR,
  GREEN_COLOR,
  LIGHT_BLUE_COLOR,
  PX12,
} from "theme/consts"
import BadgeBase from "ui/Badge"
import ButtonGroupBase from "ui/ButtonGroup"
import ContextMenu from "ui/ContextMenu"
import Group from "ui/Group"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import TextButton from "ui/TextButton"
import { MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE } from "../consts"
import PlaylistMenuCircle from "./PlaylistMenuCircle"

export const schoolListBadge = (list: UserPlaylist) => {
  if (!list.status) return null
  return list.status === "PUBLISHED" ? (
    <Badge backgroundColor={"#4f4d84"}>
      <Text bold size={PX12}>
        <Value name="apps.web.src.playlists.components.schoollistitem.zichtbaar_voor_collegas">
          {`Zichtbaar voor collega's`}
        </Value>
      </Text>
    </Badge>
  ) : (
    <Badge backgroundColor={"#f2f2f6"} color="#4f4d84">
      <Text bold size={PX12}>
        <Value name="apps.web.src.playlists.components.schoollistitem.zichtbaar_voor_collegas">
          {`Onzichtbaar voor collega's`}
        </Value>
      </Text>
    </Badge>
  )
}

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  border-radius: 8px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
  width: 100%;
  height: 100%;
`

const RoundedButton = styled(RoundedButtonBase)`
  font-size: ${PX12};
`

const GrowButton = styled(RoundedButton)`
  flex: 1;
`

const ButtonGroup = styled(ButtonGroupBase)``

const Badge = styled(BadgeBase)`
  width: max-content;
`

const SchoollistItem = ({
  list,
  // onAddLesson,
  onDelete,
  onPublish,
  onUnpublish,
  onShare,
  // onUnarchive,
}: {
  list: GetSchoolListsResult[number]
  // onAddLesson: () => void
  onDelete: () => void
  onPublish: () => void
  onUnpublish: () => void
  onShare: () => void
  // onUnarchive: () => void
}) => {
  const [openShareList, setOpenShareList] = useRouterQueryParam(
    MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE,
    "string"
  )
  return (
    <div>
      <BulletinAddListItem
        heading={
          <Value name="apps.web.src.bulletinboard.components.bulletinaddschoollistitem.schoollijst">
            Schoollijst
          </Value>
        }
        title={
          <TextButton
            color={DARK_BLUE_COLOR}
            onClick={() => setOpenShareList(list.id.toString())}
          >
            <Group gap="8px" align="center" justify="flex-start">
              <PlaylistMenuCircle
                color={list.playlist?.color || GREEN_COLOR}
                accessLevel={list.accessLevel}
                type={list.type}
              />
              {list.playlist?.displayTitle}
            </Group>
          </TextButton>
        }
        description={list.playlist?.description}
        activeGrades={list.playlist?.grades}
        image={
          <>
            {list.playlist?.lessons ? (
              <ImageGrid>
                {list.playlist?.lessons.map(
                  (lesson) =>
                    lesson.lesson.cardImage && (
                      <Image
                        alt=""
                        file={lesson.lesson.cardImage}
                        key={lesson.lesson.id}
                        width={115}
                        height={65}
                      />
                    )
                )}
                {list.playlist?.lessons &&
                  [...Array(4 - list.playlist?.lessons.length)].map(
                    function () {
                      return (
                        <EmptyContainer>
                          <EmptyImage />
                        </EmptyContainer>
                      )
                    }
                  )}
              </ImageGrid>
            ) : null}
          </>
        }
        right={
          <>
            <Stack justify="space-between">
              <Stack gap="8px">
                {schoolListBadge(list)}
                {list.owner && (
                  <>
                    <Text bold size={PX12}>
                      <Value name="apps.web.src.bulletinboard.components.bulletinaddschoollistitem.gemaakt_door">
                        Gemaakt door
                      </Value>
                    </Text>
                    <Stack gap="4px">
                      <Group gap="8px" align="center" justify="flex-start">
                        <Avatar user={list.owner} width={20} height={20} />
                        <Text size={PX12}>{list.owner.firstName}</Text>
                      </Group>
                    </Stack>
                  </>
                )}
              </Stack>
              <ButtonGroup>
                <GrowButton
                  size="small"
                  variant="filled"
                  onClick={() => setOpenShareList(list.id.toString())}
                >
                  <Value name="apps.web.src.playlists.components.schoollistitem.bekijk_lijst">
                    Bekijk lijst
                  </Value>
                </GrowButton>
                <ContextMenu
                  trigger={
                    <RoundedButton color="orange" size="small" variant="filled">
                      <FaEllipsisH />
                    </RoundedButton>
                  }
                  items={
                    <>
                      <ContextMenu.Item
                        onClick={() => setOpenShareList(list.id.toString())}
                      >
                        <Group align="center" gap="8px">
                          {/* <TbArrowRight color={DIMMED_BLUE_COLOR} size="18px" /> */}
                          <Value name="apps.web.src.playlists.components.schoollistitem.context_bekijk_lijst">
                            Bekijk lijst
                          </Value>
                        </Group>
                      </ContextMenu.Item>
                      {list.status === "PUBLISHED" ? (
                        <ContextMenu.Item onClick={onUnpublish}>
                          <Group align="center" gap="8px">
                            <Value
                              name="apps.web.src.playlists.components.schoollistitem.lijst_concept_maken"
                              version="110"
                            >
                              {/* HTML */ `Maak onzichtbaar voor collega's`}
                            </Value>
                          </Group>
                        </ContextMenu.Item>
                      ) : (
                        <ContextMenu.Item onClick={onPublish}>
                          <Group align="center" gap="8px">
                            <Value
                              name="apps.web.src.playlists.components.schoollistitem.lijst_publiceren"
                              version="111"
                            >
                              {/* HTML */ `Maak zichtbaar voor collega's`}
                            </Value>
                          </Group>
                        </ContextMenu.Item>
                      )}
                      <ContextMenu.Separator />
                      {list.status === "PUBLISHED" && (
                        <>
                          <ContextMenu.Item onClick={onShare}>
                            <Group align="center" gap="8px">
                              {/* <TbPlus color={DIMMED_BLUE_COLOR} size="18px" /> */}
                              <Value name="apps.web.src.playlists.components.schoollistitem.deel_lijst">
                                Deel lijst
                              </Value>
                            </Group>
                          </ContextMenu.Item>
                          <ContextMenu.Separator />
                        </>
                      )}
                      <ContextMenu.Item onClick={onDelete}>
                        <Group align="center" gap="8px">
                          {/* <TbTrash color={DIMMED_BLUE_COLOR} size="18px" /> */}
                          <Value name="apps.web.src.playlists.components.schoollistitem.verwijder_lijst">
                            Verwijder lijst
                          </Value>
                        </Group>
                      </ContextMenu.Item>
                    </>
                  }
                />
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )
}

export default SchoollistItem
