import { Value } from "@stringtale/react"
import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { useIntersection } from "@mantine/hooks"
import * as Dialog from "@radix-ui/react-dialog"
import AddLessonSelection from "app/playlists/components/AddLessonSelection"
import { RADIUS } from "app/playlists/consts"
import getUserList from "app/playlists/queries/getUserList"
import SearchFilters from "app/search/components/SearchFilters"
import SearchProvider, {
  useSearchContext,
} from "app/search/components/SearchProvider"
import { AnimatePresence, motion } from "framer-motion"
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react"
import { FaSearch } from "@react-icons/all-files/fa/FaSearch"
import { TbBackspaceFilled } from "@react-icons/all-files/tb/TbBackspaceFilled"
import {
  DARKER_GREY_COLOR,
  MODAL_OVERLAY_Z_INDEX,
  MODAL_Z_INDEX,
  ORANGE_COLOR,
  PX14,
  PX24,
} from "theme/consts"
import { poppins } from "theme/fonts"
import { MODAL_BOX_SHADOW } from "theme/styles"
import Button from "ui/Button"
import Group from "ui/Group"
import Hr from "ui/Hr"
import InputBase from "ui/Input"
import * as Modal from "ui/Modal"
import * as ScrollArea from "ui/ScrollArea"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Title from "ui/Title"
import AddLessonListItem from "./AddLessonListItem"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import Loader from "ui/Loader"
import Center from "ui/Center"

const HEADER_HEIGHT = 120
const MODAL_PADDING = 32

const Root = styled.div`
  width: calc(100vw - 142px);
  height: calc(100vh - 50px);
  min-width: 925px;
  position: fixed;
  top: 25px;
  left: 25px;
  bottom: 25px;
  right: 25px;
  z-index: ${MODAL_Z_INDEX};
  background-color: white;
  border-radius: ${RADIUS} 0 0 ${RADIUS};
  font-family: ${poppins.style.fontFamily};
  ${MODAL_BOX_SHADOW};

  @media screen and (max-width: 1200px) {
    width: unset;
  }
`.withComponent(motion.div)

const Content = styled.div`
  display: grid;
  grid-template-rows: ${HEADER_HEIGHT}px calc(
      100vh - 50px - ${MODAL_PADDING * 2}px - ${HEADER_HEIGHT}px
    );
  padding: ${MODAL_PADDING}px;
`

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: ${MODAL_OVERLAY_Z_INDEX};
`.withComponent(motion.div)

const Header = styled(Stack)`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`

const InputWrapper = styled(Group)`
  width: 100%;
  align-items: stretch;
`

const InputButton = styled(Button)`
  background-color: ${ORANGE_COLOR};
  border-radius: 0px 8px 8px 0px;
  color: white;
  width: 66px;
`

const Input = styled(InputBase)`
  background: white;
  flex: 1;
  border-radius: 8px 0px 0px 8px;
  width: 100%;
`

const SearchGroup = styled(Group)`
  height: 100%;

  @media screen and (max-width: 1500px) {
    gap: 40px;
  }
`

const ListItems = styled(Stack)`
  margin-bottom: 104px;
`

const ScrollAreaRoot = styled(ScrollArea.Root)`
  width: 400px;
`

type Props = {
  playlistId: number
  setIsAddLessonOpen: Dispatch<SetStateAction<boolean>>
}

const InnerSearch = ({
  setSelectedLessons,
  selectedLessons,
  userList,
  inputRef,
}) => {
  const { hits, next, isEnd } = useSearchContext()
  const { ref, entry } = useIntersection({
    threshold: 1,
  })
  useEffect(() => {
    if (entry?.isIntersecting) {
      next()
    }
  }, [entry?.isIntersecting, next])

  return (
    <SearchGroup gap="80px">
      <ScrollAreaRoot type="auto">
        <ScrollArea.Viewport>
          <SearchFilters inputRef={inputRef} />
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar>
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar>
      </ScrollAreaRoot>
      <Stack>
        <Stack gap="16px">
          <Group justify="space-between">
            <Text size={PX14}>
              <Value
                name="apps.web.src.playlists.components.addlessonoverlay.alle_methodes"
                format={{
                  b: (c) => <Text bold>{c}</Text>,
                  count: hits?.pages[0]?.totalHits,
                }}
              >
                {`<b>Alle methodes:</b> {count} items`}
              </Value>
            </Text>
          </Group>
          <Hr />
        </Stack>
        <ScrollArea.Root type="auto">
          <ScrollArea.Viewport>
            <ListItems>
              <AnimatePresence>
                {hits?.pages.map((h) =>
                  h?.hits.map((hit) => (
                    <AddLessonListItem
                      selectedLessons={selectedLessons}
                      setSelectedLessons={setSelectedLessons}
                      lesson={hit}
                      isInPlaylist={
                        userList?.playlist
                          ? userList.playlist.lessons.some(
                              (el) => el.lesson.uuid === hit.uuid
                            )
                          : false
                      }
                    />
                  ))
                )}
                {isEnd ? null : <div ref={ref}></div>}
              </AnimatePresence>
            </ListItems>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar>
            <ScrollArea.Thumb />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </Stack>
    </SearchGroup>
  )
}

export default function AddLessonOverlay({
  playlistId,
  setIsAddLessonOpen,
}: Props) {
  const [search, setSearch] = useRouterQueryParam("q")
  const [userList] = useQuery(getUserList, { id: playlistId })

  const inputRef = useRef<HTMLInputElement | null>(null)

  const [selectedLessons, setSelectedLessons] = useState<string[] | null>([])

  return (
    <Dialog.Portal forceMount>
      <Overlay
        key="addLessonOverlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
        }}
      />
      <Dialog.Content forceMount>
        <Root
          key="addLessonModal"
          initial={{
            x: -500,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          exit={{
            x: -500,
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }}
          transition={{
            ease: "easeInOut",
            duration: 0.3,
            opacity: { ease: "linear", duration: 0.2 },
          }}
        >
          <Content>
            <Header gap="16px">
              <Title size={PX24} weight="900">
                <Value
                  format={{
                    lijst: userList?.playlist?.displayTitle,
                  }}
                  name="apps.web.src.playlists.components.addlessonoverlay.lessen_toevoegen_aan"
                >
                  {`Lessen toevoegen aan {lijst}`}
                </Value>
              </Title>
              <InputWrapper>
                <Input
                  // autoFocus
                  inputRef={(ref) => {
                    inputRef.current = ref
                    if (ref) {
                      ref.focus({
                        preventScroll: true,
                      })
                    }
                  }}
                  placeholder="Zoek lessen en liedjes..."
                  defaultValue={search || ""}
                  onChange={(e) => {
                    setSearch(e.currentTarget.value)
                  }}
                  right={
                    search ? (
                      <Button
                        onClick={() => {
                          setSearch("")
                          if (inputRef.current) {
                            inputRef.current.value = ""
                          }
                        }}
                      >
                        <TbBackspaceFilled
                          color={`${DARKER_GREY_COLOR}66`}
                          size={35}
                        />
                      </Button>
                    ) : undefined
                  }
                />
                <InputButton>
                  <FaSearch size={20} />
                </InputButton>
              </InputWrapper>

              <Dialog.Close asChild>
                <Modal.Close />
              </Dialog.Close>
            </Header>
            <Suspense fallback={<Center><Loader /></Center>}>
              <SearchProvider search={search}>
                <InnerSearch
                  selectedLessons={selectedLessons}
                  setSelectedLessons={setSelectedLessons}
                  userList={userList}
                  inputRef={inputRef}
                />
              </SearchProvider>
            </Suspense>
            <AnimatePresence>
              {selectedLessons?.length !== 0 && (
                <AddLessonSelection
                  playlistId={playlistId}
                  userPlaylist={userList}
                  selectedLessons={selectedLessons}
                  setSelectedLessons={setSelectedLessons}
                  setIsAddLessonOpen={setIsAddLessonOpen}
                />
              )}
            </AnimatePresence>
          </Content>
        </Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}
