import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown"
import { FaCaretUp } from "@react-icons/all-files/fa/FaCaretUp"
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft"
import { Value, htmlFormatTags } from "@stringtale/react"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import AvatarBase from "app/files/components/Avatar"
import { GetAllGradesResult } from "app/grades/queries/getAllGrades"
import deleteShare from "app/share/mutations/deleteShare"
import { useMemo, useState } from "react"
import TruncateMarkup from "react-truncate-markup"
import {
  DARK_BLUE_COLOR,
  ORANGE_COLOR,
  DIMMED_BLUE_COLOR,
  LIGHT_GREY_COLOR,
} from "theme/colors"
import { PX10, PX12, PX24 } from "theme/sizes"
import Button from "ui/Button"
import Group from "ui/Group"
import Hr from "ui/Hr"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Title from "ui/Title"
import getFullname from "utils/users/getFullName"
import { MODAL_QUERY_SHARE_LIST_KEY } from "../consts"
import getSharedWithMe, {
  GetSharedWithMeResult,
} from "../queries/getSharedWithMe"
import getShareTitle from "../utils/getShareTitle"
import { PlaylistModalContent } from "./PlaylistModalContent"
import { Header } from "./PlaylistModalHeader"
import ShareLearningLineItem from "./ShareLearnLineItem"
import ShareLessonItem from "./ShareLessonItem"
import SharePlaylistItem from "./SharePlaylistItem"
import ShareThemeItem from "./ShareThemeItem"
import { useCurrentYear } from "app/schooljaren/hooks/useCurrentYear"
import { useHasArchiveYears } from "app/schooljaren/hooks/useHasArchiveYears"
import getArchiveYears from "app/schooljaren/queries/getArchiveYears"
import { TbChevronDown } from "@react-icons/all-files/tb/TbChevronDown"
import ContextMenu from "ui/ContextMenu"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { PX14 } from "theme/sizes"

const Content = styled.div`
  padding: 32px;
  padding-top: 16px;
  overflow: auto;
`

const Card = styled.div`
  width: 266px;
  background: #fef8b8;
  box-shadow: 2px 2px 6px #0000001a;
  border-radius: 4px;
  padding: 12px;
  padding-right: 16px;
  display: flex;
  gap: 10px;
  flex: 0 0 266px;
`

const MessageOverlay = styled.div`
  background: transparent linear-gradient(180deg, #fef8b800 0%, #fef8b8 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`

const BackHeader = styled.div`
  padding: 6px 32px;
  background-color: #f2f2f6;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
`

const Avatar = styled(AvatarBase)`
  border: 1px solid rgba(0, 0, 0, 0.2);
`

const Description = styled.div`
  white-space: pre-wrap;
  max-height: 250px;
  overflow-y: auto;
`

export const Message = ({ children }: { children?: string | null }) => {
  const [isShowMore, setShowMore] = useState(false)
  const [isTruncated, setTruncated] = useState(false)
  if (!children) {
    return null
  }
  return (
    <Stack gap="4px">
      {isShowMore ? (
        <Text size={PX12} block>
          <Description>{children}</Description>
        </Text>
      ) : (
        <TruncateMarkup lines={3} onTruncate={setTruncated}>
          <Text size={PX12} block style={{ position: "relative" }}>
            {isTruncated ? <MessageOverlay /> : null}
            <Description>{children}</Description>
          </Text>
        </TruncateMarkup>
      )}
      {isTruncated ? (
        <Text
          size={PX10}
          underline
          pointer
          onClick={() => setShowMore((s) => !s)}
        >
          {isShowMore ? (
            <>
              <Value name="apps.web.src.playlists.components.sharecontent.toon_minder">
                Toon minder
              </Value>{" "}
              <FaCaretUp />
            </>
          ) : (
            <>
              <Value name="apps.web.src.playlists.components.sharecontent.toon_meer">
                Toon meer
              </Value>{" "}
              <FaCaretDown />
            </>
          )}
        </Text>
      ) : null}
    </Stack>
  )
}

const ItemRoot = styled(Group)`
  gap: 24px;
  border-bottom: 2px solid ${LIGHT_GREY_COLOR};
  padding: 24px 0;
  align-items: flex-start;
`

const TYPES_TITLES = {
  LESSON: "les",
  PLAYLIST: "lijst",
  THEME: "thema",
  LL: "leerlijn",
}

const ItemSwitch = ({ item }: { item: GetSharedWithMeResult[number] }) => {
  const [deleteMutation] = useMutation(deleteShare)
  const onDelete = async () => {
    await deleteMutation({ id: item.id })
    await invalidateQuery(getSharedWithMe)
  }
  if (item.type === "PLAYLIST" && item.playlist) {
    return <SharePlaylistItem item={item} onDelete={onDelete} />
  }
  if (item.type === "LESSON" && item.lesson) {
    return <ShareLessonItem lesson={item.lesson} onDelete={onDelete} />
  }
  if (item.type === "THEME" && item.themeGroup) {
    return (
      <ShareThemeItem
        themeGroup={item.themeGroup}
        owner={item.sender}
        onDelete={onDelete}
      />
    )
  }
  if (item.type === "LL" && item.learningLine) {
    return (
      <ShareLearningLineItem
        learningLine={item.learningLine}
        owner={item.sender}
        onDelete={onDelete}
      />
    )
  }
  return null
}

const ContentPadding = styled.div`
  padding: 32px 0;
`

export const Item = ({ item }: { item: GetSharedWithMeResult[number] }) => {
  if (!item) {
    return null
  }
  return (
    <ItemRoot>
      <Card>
        {item.sender ? <Avatar user={item.sender} /> : null}
        <Stack gap="4px">
          <Text bold size={PX12}>
            <Value
              name="apps.web.src.playlists.components.playlistmodalsharecontent.lijst_gedeeld"
              format={{
                username: item.sender ? getFullname(item.sender) : "Iemand",
                listname: getShareTitle(item),
                type: TYPES_TITLES[item.type],
              }}
              version="1"
            >
              {`{username} heeft de {type} {listname} gedeeld`}
            </Value>
          </Text>
          <Text color={DIMMED_BLUE_COLOR} size={PX12}>
            <Value
              name="apps.web.src.playlists.components.playlistmodalsharecontent.date"
              format={{ date: item.createdAt }}
              locales="nl"
            >
              {`{date, date, ::HH:mm op d MMMM yyyy}`}
            </Value>
          </Text>
          <Message>{item.message}</Message>
        </Stack>
      </Card>
      <ItemSwitch item={item} />
    </ItemRoot>
  )
}

const StyledHeader = styled(Header)`
  padding: 22px 80px 22px 32px;
  flex-direction: row;
  justify-content: space-between;
`

export default function PlaylistModalShareContent({
  grades,
}: {
  grades: GetAllGradesResult
}) {
  const [archiveYearSlug, setArchiveYearSlug] = useState<string | null>(null)
  const currentYear = useCurrentYear()
  const hasArchiveYears = useHasArchiveYears()
  const [archiveYears] = useQuery(getArchiveYears, undefined, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const [items] = useQuery(getSharedWithMe, { slug: archiveYearSlug })
  const [openList, setOpenList] = useRouterQueryParam(
    MODAL_QUERY_SHARE_LIST_KEY,
    "string"
  )
  const openListId = openList ? parseInt(openList) : null
  const share = useMemo(() => {
    return items.find((item) => item.id === openListId)
  }, [items, openListId])

  return (
    <Stack grow overflow="hidden">
      {share?.playlistId ? (
        <>
          <BackHeader>
            <Button onClick={() => setOpenList(null)}>
              <Group gap="8px" align="center">
                <FaChevronLeft color={DARK_BLUE_COLOR} size={12} />
                <Text color={DARK_BLUE_COLOR} bold size="13px">
                  <Value
                    name="apps.web.src.playlists.components.sharecontent.terug_naar_gedeeld_met_mij"
                    version="1"
                  >
                    Terug naar gedeeld met mij
                  </Value>
                </Text>
              </Group>
            </Button>
          </BackHeader>
          <PlaylistModalContent
            id={share.playlistId}
            grades={grades}
            onClose={() => setOpenList(null)}
            setIsNewPlaylistOpen={() => {}}
            setIsAddLessonOpen={() => {}}
            setMigrationModalVisibe={() => {}}
            isSharedWithMe
            addedByUser={share.sender}
          />
        </>
      ) : (
        <>
          <StyledHeader color="#F5F5F5">
            <Text color={DARK_BLUE_COLOR} bold size={PX24}>
              <Value name="apps.web.src.playlists.components.sharecontent.gedeeld_met_mij">
                Gedeeld met mij
              </Value>
            </Text>
            {hasArchiveYears && (
              <ContextMenu
                trigger={
                  <Group gap="4px" align="center">
                    <Text color={ORANGE_COLOR} bold size={PX24}>
                      {archiveYearSlug
                        ? archiveYearSlug
                        : currentYear?.displayTitle}
                    </Text>
                    <TbChevronDown size={26} color={ORANGE_COLOR} />
                  </Group>
                }
                items={
                  <>
                    <DropdownMenu.Item
                      asChild
                      onSelect={async () => {
                        setArchiveYearSlug(null)
                      }}
                    >
                      <ContextMenu.Item isActive={archiveYearSlug === null}>
                        {currentYear?.displayTitle}
                      </ContextMenu.Item>
                    </DropdownMenu.Item>
                    {archiveYears?.archiveYears.map((archiveYear) => (
                      <DropdownMenu.Item
                        asChild
                        onSelect={async () => {
                          setArchiveYearSlug(archiveYear.slug)
                        }}
                      >
                        <ContextMenu.Item
                          isActive={archiveYearSlug === archiveYear.slug}
                        >
                          {archiveYear.displayTitle}
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    ))}
                  </>
                }
              />
            )}
          </StyledHeader>
          <Content>
            {items.length > 0 ? (
              <>
                <Hr />
                {items.map((item) => (
                  <Stack>
                    <Item item={item} />
                  </Stack>
                ))}
              </>
            ) : (
              <ContentPadding>
                <Title size={PX14}>
                  <Value name="apps.web.src.playlists.components.sharecontent.empty_state_title">
                    Er is nog niets met je gedeeld...
                  </Value>
                </Title>
              </ContentPadding>
            )}
          </Content>
        </>
      )}
    </Stack>
  )
}
