import { useRouter } from "next/router"
import { Dispatch, SetStateAction, useMemo } from "react"

  ; import useLinks from "./useLinks"
export default function useRouterQueryParam<T>(name: string): [T, Dispatch<SetStateAction<T>>]
  ; export default function useRouterQueryParam(name: string, type?: "string"): [string | null, Dispatch<SetStateAction<string | null>>]
  ; export default function useRouterQueryParam(name: string, type: "number"): [number, Dispatch<SetStateAction<number>>]
  ; export default function useRouterQueryParam(name: string, type: "boolean"): [boolean, Dispatch<SetStateAction<boolean>>]

  ; export default function useRouterQueryParam(name: string, type: "string" | "number" | "boolean" = "string") {
    const router = useRouter()
    const getLink = useLinks()
    const value = useMemo(() => {
      let value: any = router.query[name]
      if (Array.isArray(value)) {
        value = value[0]
      }
      if (type === "number") {
        return parseInt(value)
      }
      if (type === "boolean") {
        return value !== undefined
      }
      return value
    }, [name, router.query, type])
    return [value, (newValueOrFunction: SetStateAction<any>) => {
      if (typeof newValueOrFunction === "function") {
        newValueOrFunction = newValueOrFunction(value)
      }
      let query = { ...router.query }
      // Remove key starting with __
      Object.keys(query).forEach(key => {
        if (key.startsWith("__")) {
          delete query[key]
        }
      })
      if (type === "boolean") {
        if (newValueOrFunction === false) {
          delete query[name]
        } else {
          query = {
            ...query,
            [name]: "",
          }
        }
      } else {
        if (newValueOrFunction === null) {
          delete query[name]
        } else {
          query = {
            ...query,
            [name]: newValueOrFunction ? String(newValueOrFunction) : newValueOrFunction,
          }
        }
      }
      if (query.host) {
        delete query.host
      }
      router.push(getLink({
        pathname: router.pathname,
        query,
      }))
    }] as const
  }
