import { useQuery } from "@blitzjs/rpc"
import {
  useQuery as useQueryTan,
  useInfiniteQuery,
  InfiniteData,
} from "react-query"
import MeiliSearch, {
  FacetDistribution,
  Hits,
  SearchParams,
  SearchResponse,
} from "meilisearch"
import {
  Dispatch,
  SetStateAction,
  createContext,
  use,
  useCallback,
  useContext,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import useRouterQueryParamList from "app/core/hooks/useRouterQueryParamList"
import getCredentials from "../queries/getCredentials"
import { SearchElement } from "../types"
import { ContentSubjectType } from "db"
import { useRouter } from "next/router"
import { useCurrentLicenseIds } from "app/core/hooks/useCurrentLicenseIds"

type Props = {
  hits: InfiniteData<SearchResponse<SearchElement>> | undefined
  search: string | null
  // setSearch: Dispatch<SetStateAction<string | null>>
  contentSubjectType: ContentSubjectType | null
  setContentSubjectType: Dispatch<SetStateAction<ContentSubjectType | null>>
  groups: number[]
  setGroups: Dispatch<SetStateAction<number[]>>
  filter: string[]
  setFilter: Dispatch<SetStateAction<string[]>>
  participants: number[]
  setParticipants: Dispatch<SetStateAction<number[]>>
  facetDistribution: FacetDistribution | undefined
  clear: (clearSearch?: boolean) => void
  next: () => void
  isEnd: boolean
}

const Context = createContext<Props | null>(null)

export default function SearchProvider({
  search,
  children,
}: {
  children: React.ReactNode
  search: string | null
}) {
  const [cred] = useQuery(getCredentials, undefined)
  const router = useRouter()
  const licenseIds = useCurrentLicenseIds()

  const [contentSubjectType, setContentSubjectType] =
    useRouterQueryParam<ContentSubjectType | null>("subject")
  const [groups, setGroups] = useRouterQueryParamList("groepen", "number")
  const [filter, setFilter] = useRouterQueryParamList("filter", "string")
  const [participants, setParticipants] = useRouterQueryParamList(
    "meedoen",
    "number"
  )

  const deferredSearch = useDeferredValue(search)

  const index = useMemo(() => {
    const client = new MeiliSearch({
      host: cred.host,
      apiKey: cred.apiKey,
    })
    return client.index("Lesson")
  }, [cred.apiKey, cred.host])

  const filterStrings = useMemo(() => {
    let filterStrings: string[] = []
    if (contentSubjectType) {
      filterStrings.push(`contentSubject = '${contentSubjectType}'`)
    }
    if (groups.length > 0) {
      filterStrings.push(
        groups.map((group) => `grades.id = ${group}`).join(" AND ")
      )
    }
    if (participants.length > 0) {
      filterStrings.push(
        participants
          .map((icon) => `subjects.subjects.participationIcons.id = ${icon}`)
          .join(" AND ")
      )
    }
    if (filter.length > 0) {
      filterStrings.push(
        filter
          .map(
            (f) =>
              `(filterOptions.displayTitle = \"${f}\" OR subjects.subjects.filterOptions.displayTitle = \"${f}\")`
          )
          .join(" AND ")
      )
    }

    if (licenseIds.length > 0) {
      filterStrings.push(
        licenseIds.map((id) => `licenses.id = ${id}`).join(" OR ")
      )
    }

    filterStrings.push(`status = \"PUBLISHED\"`)
    return filterStrings
  }, [contentSubjectType, groups, filter, participants, licenseIds])

  // Load results
  const {
    data: hits,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: `search-${deferredSearch}-${filterStrings.join("-")}`,
    queryFn: useCallback(
      async ({ pageParam = 1 }) => {
        const options = {
          hitsPerPage: 15,
          page: pageParam,
          filter: filterStrings.length === 0 ? undefined : filterStrings,
          facets: [
            "grades.id",
            "subjects.subjects.participationIcons.id",
            "filterOptions.id",
            "subjects.subjects.filterOptions.id",
          ],
          sort: !deferredSearch ? ["updatedAt:desc"] : undefined,
          attributesToHighlight: ["*"],
        } satisfies SearchParams

        const response = await index.search<SearchElement, typeof options>(
          deferredSearch || "",
          options
        )
        return response
      },
      [filterStrings, deferredSearch, index]
    ),
    getNextPageParam: (lastPage) =>
      lastPage.totalPages === lastPage.page ? undefined : lastPage.page + 1,
    getPreviousPageParam: () => undefined,
    suspense: true,
  })

  const clear = useCallback(
    (clearSearch: boolean = true) => {
      router.push({
        pathname: router.pathname,
        query: clearSearch ? {} : { q: router.query.q },
      })
    },
    [router]
  )

  const facetDistribution = useMemo(() => {
    if (!hits) {
      return undefined
    }
    const p = hits.pages
    const lastPage = p[p.length - 1]
    return lastPage.facetDistribution
  }, [hits])

  return (
    <Context.Provider
      value={{
        hits,
        search,
        contentSubjectType,
        setContentSubjectType,
        groups,
        setGroups,
        filter,
        setFilter,
        participants,
        setParticipants,
        facetDistribution,
        clear,
        next: fetchNextPage,
        isEnd: !hasNextPage,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export function useSearchContext() {
  const context = useContext(Context)
  if (!context) {
    throw new Error("useSearchContext must be used within a SearchProvider")
  }
  return context
}
