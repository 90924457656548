import { Value, htmlFormatTags } from "@stringtale/react"
import { Routes } from "@blitzjs/next"
import styled from "@emotion/styled"
import Link from "next/link"
import { ReactNode } from "react"
import { PX12, PX14, PX20 } from "theme/sizes"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Title from "ui/Title"
import { GetUserListResult } from "../queries/getUserList"
import TextLink from "ui/TextLink"

const ContentPadding = styled.div`
  padding: 32px 0;
`

const EmptyState = styled.div`
  background-color: #f5f5f5;
  border-radius: 16px;
  padding: 24px;
`

export default function PlaylistModalEmptyState({
  userList,
  addButton,
}: {
  userList: NonNullable<GetUserListResult>
  addButton: ReactNode
}) {
  if (userList.accessLevel === "GROUP") {
    return (
      <ContentPadding>
        <Title size={PX14}>
          <Value name="apps.web.src.playlists.components.playlistmodalemptystate.group.title">
            Deze lijst is nog leeg...
          </Value>
        </Title>
        <Stack gap="15px">
          <Value
            name="apps.web.src.playlists.components.playlistmodalemptystate.group.body"
            format={{
              ...htmlFormatTags,
              p: (c) => <Text size={PX14}>{c}</Text>,
              link: (c) => <Link {...Routes.LeerkrachtIndexPage()}>{c}</Link>,
            }}
            version="1"
          >
            {`<p>Voeg liedjes en lessen toe voor jouw groep.</p>`}
          </Value>
        </Stack>
      </ContentPadding>
    )
  }
  if (userList.accessLevel === "KIDS") {
    return (
      <ContentPadding>
        <Stack gap="30px">
          <Stack gap="15px">
            <Value
              name="apps.web.src.playlists.components.playlistmodalemptystate.kids"
              format={{
                ...htmlFormatTags,
                p: (c) => <Text size={PX14}>{c}</Text>,
                addButton: <div>{addButton}</div>,
                b: (c) => (
                  <Text size={PX14} bold>
                    {c}
                  </Text>
                ),
                br: <br />,
                kids_link: (c) => <a href="https://kids.123zing.nl">{c}</a>,
                link1: (c) => <Link {...Routes.LeerlingcodesPage()}>{c}</Link>,
                p_margin_top: (c) => (
                  <Text size={PX14} style={{ marginTop: 15 }}>
                    {c}
                  </Text>
                ),
                lees_meer_link: (c) => (
                  <a
                    href="https://123zing.my.site.com/knowledgebase/s/article/hoe-gebruik-ik-de-kidspagina"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {c}
                  </a>
                ),
              }}
            >
              {`
              <p>
                Laat leerlingen zelfstandig op school of thuis verdergaan met
                123ZING via de Kidspagina van hun groep. Op de Kidspagina vinden
                de leerlingen de Kidslijst en de Kids Studio.
                <ul>
                   <li>
                    Op de Kidslijst zet de leerkracht liedjes en lessen klaar.
                  </li>
                   <li>
                    In de Kids Studio kunnen leerlingen op een laagdrempelige
                    manier componeren.
                  </li>
                </ul>
              </p>
              {addButton}
               <p_margin_top>
                 <b>
                  Heb je al leerlingcodes aangemaakt?
                </b>
                 {br}
                Via <kids_link>kids.123zing.nl</kids_link> kunnen de leerlingen met hun eigen<link1> leerlingcode</link1> inloggen op de Kidspagina van hun groep.
              </p_margin_top>
               <p>
                Let op: Om gebruik te maken van de Kidspagina is minimaal 1
                leerlingcode vereist. Na het aanmaken van de leerlingcode(s)
                worden de loginnaam en de informatiebrief beschikbaar.     
              </p>
               <p>
                 <lees_meer_link>
                  Lees meer over de Kidspagina en het aanmaken van leerlingcodes.          
                </lees_meer_link>
              </p>
              `}
            </Value>
          </Stack>
          <video
            src="https://e06d971082994868aa2ed86d8d5fbedf.objectstore.eu/123zing/production/static_assets/new_platform/uitleg-kidspagina.mp4?v=1652269774"
            width="640"
            controls
          />
        </Stack>
      </ContentPadding>
    )
  }
  if (userList.accessLevel === "SCHOOL" && userList.status !== "PUBLISHED") {
    return (
      <ContentPadding>
        <EmptyState>
          <Stack gap={"4px"}>
            <Title size={PX20}>
              <Value name="apps.web.src.playlists.components.playlistmodalemptystate.deze_lijst_is_nog_een_concept">
                Deze lijst is nog een concept
              </Value>
            </Title>
            <Text size={PX12}>
              <Value name="apps.web.src.playlists.components.playlistmodalemptystate.de_schoollijst_is_een_concept_met_de_gekoppelde_groepen" version="1">
                {/* HTML */ `Deze schoollijst is nog een concept. Dat betekent dat deze nog niet zichtbaar is voor je collega's, zodat je deze eerst zelf kunt voorbereiden. Zodra je de schoollijst publiceert, kunnen de collega's van de gekoppelde groepen de lijst bekijken in hun bibliotheek.`}
              </Value>
            </Text>
          </Stack>
        </EmptyState>
      </ContentPadding>
    )
  }
  return (
    <ContentPadding>
      <Title size={PX14}>
        <Value name="apps.web.src.playlists.components.playlistmodalemptystate.deze_lijst_is_nog_leeg">
          Deze lijst is nog leeg...
        </Value>
      </Title>
      <Text size={PX14}>
        {addButton ? (
          <>
            <Value
              name="apps.web.src.playlists.components.playlistmodalemptystate.message_kan_bewerken"
              format={{
                b: (c) => <Text bold>{c}</Text>,
              }}
            >{`Voeg liedjes en lessen toe om je lijst te vullen. Klik op de knop <b>‘toevoegen’</b>
              en kies welke lessen je op jouw afspeellijst wilt zetten. Voeg
              liedjes en lessen toe om je lijst te vullen.
            `}</Value>
          </>
        ) : (
          <>
            <Value name="apps.web.src.playlists.components.playlistmodalemptystate.deze_schoollijst_is_aangemaakt_door_een_beheerder">
              Deze schoollijst is aangemaakt door een beheerder of kartrekker
              van jullie schoolaccount, maar deze persoon heeft nog geen liedjes
              of lessen toegevoegd.
            </Value>
          </>
        )}
        <br />
        <br />
        <TextLink
          href="https://123zing.my.site.com/knowledgebase/s/article/hoe-gebruik-ik-de-afspeellijsten-in-de-bibliotheek"
          target="_blank"
          rel="noreferrer"
          bold
        >
          <Value name="apps.web.src.playlists.components.playlistmodalemptystate.meer_informatie_over_de_bibliotheek">
            Meer informatie over de bibliotheek
          </Value>
        </TextLink>
        .
        <br />
        <br />
      </Text>

      {/*<video
        src="https://e06d971082994868aa2ed86d8d5fbedf.objectstore.eu/123zing/production/static_assets/new_platform/uitleg-bibliotheek.mp4?v=1652269773"
        width="640"
        controls
      />*/}
    </ContentPadding>
  )
}
