import styled from "@emotion/styled"
import { ComponentProps, LegacyRef, MutableRefObject, Ref, forwardRef } from "react"
import * as Input from "./Input"

const InputControl = styled(Input.Control)`
  height: 126px;
  width: 100%;
  box-sizing: border-box;
`.withComponent("textarea")

const TextArea = forwardRef<
  HTMLTextAreaElement,
  ComponentProps<typeof InputControl>
>(({ children, ...props }, ref) => {
  return (
    <Input.Root {...props}>
      {/* @ts-ignore */}
      <InputControl {...props} ref={ref} />
    </Input.Root>
  )
})

TextArea.displayName = "TextArea"

export default TextArea
